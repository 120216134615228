import React from 'react'
import { Link } from 'gatsby'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { NavigateNext, NavigateBefore } from '@material-ui/icons'
import PropTypes from 'prop-types'

const styles = theme => ({
    thePaging: {
        width: '100%',
        paddingBottom: theme.spacing.unit,
        '& span': {
            marginBottom: theme.spacing.unit*2,
            color: theme.palette.secondary.main,
        },
    },
    pageLinkLeft: {
        textDecoration: 'none',
        textTransform: 'uppercase',
        float: 'left'
    },
    pageLinkRight: {
        textDecoration: 'none',
        textTransform: 'uppercase',
        textAlign: 'right',
        float: 'right'
    },
    pageIcon: {
        paddingLeft: theme.spacing.unit*0.2,
        paddingRight: theme.spacing.unit*0.2,
        paddingTop: theme.spacing.unit*0.6,
        verticalAlign: 'text-bottom',
        color: theme.palette.secondary.main,
    }
})

const Paging = ({ context, classes }) => {
    const { numPages, currentPage } = context
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const isNotPaginated = isFirst & isLast

    const prevPageNum = currentPage - 1 === 1 ? '' : currentPage - 1
    const nextPageNum = currentPage + 1

    const pathPrefix = context.tag ? `/tags/${context.kebabTag}` : (context.category ? `/categories/${context.kebabCategory}`: '')
    const prevPageLink = isFirst ? null : `${pathPrefix}/${prevPageNum}/`
    const nextPageLink = isLast ? null : `${pathPrefix}/${nextPageNum}/`

    return (
        !isNotPaginated?<Typography className={classes.thePaging}>
            {!isFirst?<Link to={`${prevPageLink}`} className={classes.pageLinkLeft}><NavigateBefore className={classes.pageIcon} /><span>Newer</span></Link>:''}
            {!isLast?<Link to={`${nextPageLink}`} className={classes.pageLinkRight}><span>Older</span><NavigateNext className={classes.pageIcon} /></Link>:''}
        </Typography>:null
    )
}

Paging.propTypes = {
    context: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Paging)