import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Link } from 'gatsby'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import MinutesToRead from './common/MinutesToRead'
import Button from '@material-ui/core/Button'
import { Schedule, CalendarToday } from '@material-ui/icons'
import PostCategories from './common/PostCategories'
import PostTags from './common/PostTags'
//import Img from 'gatsby-image'
import Grid from '@material-ui/core/Grid'
import Paging from './common/Paging'
import SocialPortal from './common/SocialPortal'
import SocialShare from './common/SocialShare'

const styles = theme => ({
    blogPosts: {
        display: 'block',
        padding: `${theme.spacing.unit*4}px ${theme.spacing.unit*4}px`,
        [theme.breakpoints.up('md')]: {
            padding: `${theme.spacing.unit*4}px ${theme.spacing.unit*8}px`,
        }
    },
    postPreview: {
        paddingBottom: theme.spacing.unit * 8,
        //textAlign: 'center',
        display: 'block'
    },
    postPreviewLast: {
        paddingBottom: theme.spacing.unit * 2,
        //textAlign: 'center',
        display: 'block'
    },
    readButton: {
        marginTop: theme.spacing.unit
    },
    postTitle: {
        textDecoration: 'none',
        textTransform: 'uppercase',
        color: 'black'
    },
    postDate: {
        fontWeight: '400',
        color: 'gray',
        fontStyle: 'italic',
        marginTop: theme.spacing.unit,
        display: 'flex',
        alignItems: 'center',
        lineHeight: 'normal'
    },
    postIcons: {
        paddingLeft: theme.spacing.unit*0.2,
        paddingRight: theme.spacing.unit*0.2,
    },
    postDateDelim: {
        marginLeft: '3.6px',
        marginRight: theme.spacing.unit*0.2,
        color: 'gray',
        display: 'inline'
    },
    postExcerpt: {
        textAlign: 'left',
        paddingTop: theme.spacing.unit,
        //textIndent: '1rem'
    },
    postThumbnail: {

    }
})

const AllPosts = ({data, context, classes}) => {
    const { edges: posts } = data.allMarkdownRemark
    return (
        <div className={classes.blogPosts}>
            {posts
                .filter(post => post.node.frontmatter.title.length > 0)
                .map(({ node: post }, ndx) => {
                    const isLastPost = ndx == (posts.length-1)
                    //const fluid = post.frontmatter.thumbnail.childImageSharp.fluid
                    return (
                        <article className={isLastPost?classes.postPreviewLast:classes.postPreview} key={post.id}>
                            <Grid 
                                container={true}
                                spacing={8}
                            >
                                {/* <Grid item={true} xs={12} sm={6} lg={6}>
                                    <Img fluid={fluid} className={classes.postThumbnail} />
                                </Grid> */}
                                <Grid item={true} xs={12} sm={12} lg={12}>
                                    <Typography variant='h6'>
                                        <Link to={post.frontmatter.path} className={classes.postTitle}>{post.frontmatter.title}</Link>
                                        <PostCategories cats={post.frontmatter.categories} row={true} />
                                    </Typography>
                                    <div className={classes.postDate}>
                                        <CalendarToday className={classes.postIcons} fontSize='small' /><small>{post.frontmatter.date}</small>
                                        <Typography className={classes.postDateDelim} component='span'> • </Typography>
                                        <Schedule className={classes.postIcons} fontSize='small' /><MinutesToRead minutes={post.timeToRead} />
                                    </div>
                                    <Typography className={classes.postExcerpt}>{post.excerpt}</Typography>
                                    <PostTags tags={post.frontmatter.tags} row={true} />
                                    <Button
                                        color='secondary'
                                        variant='contained'
                                        className={classes.readButton}
                                        component={Link}
                                        to={post.frontmatter.path}
                                    >
                                        Read more
                                    </Button>
                                </Grid>
                            </Grid>
                        </article>
                    )
                })}
            <Paging context={context} />
            <SocialPortal>
                <SocialShare />
            </SocialPortal>
        </div>
    )
}

AllPosts.propTypes = {
    data: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AllPosts)